<template>
  <div class="product_gallery">
    <!-- Preview -->
    <div class="preview">
      <!-- SKELETON SLIDER -->
      <swiper
        :thumbs="{ swiper: navigationSwiperSkeleton }"
        effect="fade"
        v-if="images.length == 0"
      >
        <swiper-slide
          v-show="images.length == 0"
          v-for="index in 5"
          v-bind:key="index"
        >
          <div class="slide ratio ratio-4_3">
            <div class="body skeleton_loader"></div>
          </div>
        </swiper-slide>
      </swiper>

      <!-- REAL SLIDER -->
      <swiper
        :thumbs="{ swiper: navigationSwiper }"
        effect="fade"
        :lazy="true"
        v-if="images.length != 0"
      >
        <swiper-slide v-for="(image, index) in images" v-bind:key="index">
          <div class="slide ratio ratio-4_3">
            <div class="body">
              <img
                :data-src="window.Routes.getImage(image, 'preview')"
                class="swiper-lazy"
              />
              <div
                class="swiper-lazy-preloader swiper-lazy-preloader-white"
              ></div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <!-- /.preview -->

    <!-- Navtigation -->
    <div class="navigation">
      <!-- SKELETON NAVIGATION -->
      <swiper
        @swiper="setNavigationSwiperSkeleton"
        slidesPerView="5"
        :space-between="5"
        :watchSlidesVisibility="true"
        :watchSlidesProgress="true"
        :threshold="3"
        v-if="images.length == 0"
      >
        <swiper-slide
          v-for="index in 5"
          v-bind:key="index"
          v-show="images.length == 0"
        >
          <div class="slide ratio ratio-4_3 fake">
            <div class="body skeleton_loader"></div>
          </div>
        </swiper-slide>
      </swiper>

      <!-- REAL NAVIGATION -->
      <swiper
        @swiper="setNavigationSwiper"
        slidesPerView="5"
        :space-between="5"
        :watchSlidesVisibility="true"
        :watchSlidesProgress="true"
        :threshold="3"
        v-if="images.length != 0"
      >
        <swiper-slide v-for="(image, index) in images" v-bind:key="index">
          <div class="slide ratio ratio-4_3">
            <div class="body">
              <img :src="window.Routes.getImage(image, 'preview')" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Thumbs, EffectFade, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/components/effect-fade/effect-fade.scss";
import "swiper/components/lazy/lazy.scss";

SwiperCore.use([Thumbs, EffectFade, Lazy]);

export default {
  name: "ProductGallery",
  components: { Swiper, SwiperSlide },
  props: {
    images: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      navigationSwiper: null,
      navigationSwiperSkeleton: null,
    };
  },
  watch: {
    images: function () {
      this.navigationSwiper = null;
      this.navigationSwiperSkeleton = null;
    },
  },
  methods: {
    setNavigationSwiper(swiper) {
      this.navigationSwiper = swiper;
    },
    setNavigationSwiperSkeleton(swiper) {
      this.navigationSwiperSkeleton = swiper;
    },
  },
};
</script>

<style lang='scss' scoped>
.product_gallery {
  //GLobal Slide config
  .slide {
    background-color: map-get($colors, "gray");
    border: 1px solid map-get($defaults, "border-color");
    border-radius: map-get($defaults, "border-radius");
    overflow: hidden;

    & > .body {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .preview {
    width: 100%;
    float: left;

    .slide {
      .swiper-lazy-preloader {
        background-color: map-get($colors, "gray");
      }

      img {
        background-color: map-get($colors, "gray");

        &.swiper-lazy-loaded {
          background-color: #fff;
        }
      }
    }
  }

  .navigation {
    width: 100%;
    float: left;
    margin-top: map-get($spacings, "default");

    .swiper-slide {
      .slide {
        &:hover {
          cursor: pointer;
        }
      }
      &.swiper-slide-thumb-active {
        .slide:not(.fake) {
          border: 2px solid map-get($colors, "twiks");
        }
      }
    }
  }
}
</style>

<template>
  <div class="col-md-6">
    <button
      @click="showPackageNav"
      class="btn btn-outlined w-100 add-to-package"
    >
      {{ $t("products.add_to_package") }}
    </button>
  </div>
  <!-- Package Nav Logic -->
  <confirm-modal
    ref="modalAgreement"
    modalDialogClasses="modal-notice"
    :modalWrapper="null"
    :title="$t('packages.agreement.title')"
    :body="$t('packages.agreement.body')"
    :confirmBtn="$t('packages.agreement.button')"
    confirmBtnClass="btn btn-primary"
    declinedBtnClass="btn-default"
    :closeButton="false"
    :checkBoxText="$t('packages.agreement.checkBoxText')"
    @confirmed="openPackageNavAfterAgreement"
  >
    <button ref="packageAgreement" class="d-none"></button>
  </confirm-modal>

  <PackageNav
    ref="packageNav"
    @scrolling="paginate"
    :product="product"
    :total_price="total_price"
    :loading_ready="loading_ready"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmModal from "@/components/ConfirmModal.vue";
import packageAgreement from "@/mixins/package-agreement";
import PackageNav from "@/views/Products/partials/PackageNav.vue";

export default {
  name: "AddToPackage",
  components: { ConfirmModal, PackageNav },
  mixins: [packageAgreement],
  props: {
    product: {
      default: null,
    },
    total_price: {
      default: null,
    },
    cart_data: {
      default: null,
    },
  },
  data() {
    return {
      loading_ready: true,
    };
  },
  methods: {
    ...mapActions(["add_to_package", "add_package_product", "setErrors"]),
    showPackageNav() {
      let add_to_package = this.checkAgreement();
      if (add_to_package) {
        this.$refs.packageNav.$refs.mainPackageNav.classList.add("margin-0");
        this.$refs.packageNav.$refs.packageNavLayer.classList.add("show");
        this.add_package_product(this.cart_data);
      }
    },
    openPackageNavAfterAgreement() {
      this.acceptAgreement();
      this.$refs.packageNav.$refs.mainPackageNav.classList.add("margin-0");
      this.$refs.packageNav.$refs.packageNavLayer.classList.add("show");
      this.add_package_product(this.cart_data);
    },
    async packageIndex() {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("packages_index");

        console.log(this.product);
        this.shop_id = this.product.shop.id;
        window.axios
          .get(url, {
            params: {
              pagination: 12,
              page: this.page,
              shop_id: this.shop_id,
            },
          })
          .then(
            (response) => {
              this.add_to_package(response.data.data);
              resolve(response.data);
            },
            (error) => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
          );
      });
    },
    paginate(e) {
      const SCROLL_PIXEL_NEAR_END = 50;
      if (this.packages?.length < this.meta?.total) {
        if (
          e.target.scrollHeight <=
          e.target.scrollTop + e.target.offsetHeight + SCROLL_PIXEL_NEAR_END
        ) {
          this.page = this.page + 1;
          this.loading_ready = false;

          this.packageIndex().then(
            () => {
              this.loading_ready = true;
            },
            () => {
              //Redirect to 404
              this.$router.replace("/404");
            }
          );
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      packages: "getPackages",
    }),
  },
  watch: {
    packageGet: {
      immediate: true,
      handler() {
        console.log(this.product);
        this.packageIndex().then(
          (response) => {
            this.meta = response.meta;
            this.package_ready = true;
          },
          () => {
            //Redirect to 404
            this.$router.replace("/404");
          }
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
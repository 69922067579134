<template>
  <div class="rating color-secondary">
    <template v-if="rating !== null">
      <div class="row justify-content-between">
        <div class="col-auto Aligner items-left">
          <i class="fas fa-star color-secondary"></i>
          <span class="value font-size-md ps-2">{{ rating }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="product_ready === false">
        <div class="row justify-content-between">
          <div class="col-auto Aligner items-left">
            <img src="@/assets/star.svg"/>
            <div class="skeleton ms-2">
              <div class="line skeleton_loader thick w-30px mb-0"></div>
            </div>
          </div>
          <div class="col-auto font-size-small">
            <div class="skeleton ms-2">
              <div class="line skeleton_loader thick w-70px mb-0"></div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "ProductRating",
  props: {
    rating: {
      type: Number,
    },
    product_ready: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
.rating {
  img {
    margin-top: -2px;
  }
}
</style>

<template>
  <div id="shopSidebar">
    <div class="row g-0">
      <div class="col-auto col-md-12">
        <div class="logo ratio"
             :style="computedShop !== null ? 'background-image:url('+window.Routes.getImage(computedShop.logo, 'preview')+')' : ''">
          <div class="body" v-if="computedShop === null">
            <div class="skeleton_loader"></div>
          </div>
        </div>
      </div>

      <div class="name col col-md-12 mt-2">
        <div class="font-weight-bold font-size-big" v-if="computedShop !== null">
          <router-link :to="'/shops/' + computedShop.id + '?title=' + computedShop.name">
            {{ computedShop.name }}
          </router-link>
        </div>
        <div class="w-100 float-left skeleton" v-if="computedShop === null">
          <div class="line thick skeleton_loader w-90"></div>
        </div>
      </div>

      <div class="col-auto col-md-12 store-navigation">
        <router-link v-if="computedShop !== null" :to="'/shops/' + computedShop.id+ '?title=' + computedShop.name"
                     class="store-visit btn btn-outlined"
                     :disabled="computedShop === null">
          {{ $t("shops.visit_store") }}
        </router-link>

        <!-- Subscribe button -->
        <button v-if="access_token !== null" class="btn w-auto store-subscribe" :disabled="computedShop === null"
                :class="computedShop !== null && computedShop.subscribed === true ? 'btn-outlined-secondary' : 'btn-outlined'"
                @click="subscribeToShop">
          <i class="far fa-bell"></i>
        </button>
      </div>
    </div>


    <!--Rating-->
    <div class='rating row' v-if="computedShop !== null">
      <div class="col-12"><hr></div>

      <div class="col-12 Aligner items-left">
        <div v-if="computedShop.rating !== null" class="Aligner items-left">
          <i class="fas fa-star color-twiks"></i>
          <span class="value font-size-md ps-2">{{ computedShop.rating }}</span>
          <i class="fas fa-circle mx-2 color-secondary"></i>
        </div>
        <!-- Distance to shop -->
        <span class="color-secondary">{{ distanceToShop }} {{ $t("global.units.km") }}</span>
      </div>

      <!-- Opened-->
      <div class="col-12 my-2">
        <div class="badge font-size-sm"
             :class="{'bg-lightYellow color-twiks': computedShop.opened, 'bg-danger color-white': !computedShop.opened}">
          {{ computedShop.opened ? $t("global.open") : $t("global.closed") }}
        </div>
        <!-- Open -->
        <span class="ms-2" v-if="computedShop.opened && computedShop.closes_at !== null">
          {{ $t("shops.closes_at") + ": " + moment(computedShop.closes_at).local().format("ddd LT") }}
        </span>
        <!-- Closed -->
        <span class="ms-2" v-if="!computedShop.opened && computedShop.opens_at !== null">
          {{ $t("shops.opens_at") + ": " + moment(computedShop.opens_at).local().format("ddd LT") }}
        </span>
      </div>

      <!-- Location -->
      <div class="col-12">
        <i class="fas fa-map-marker-alt color-secondary"></i> {{ computedShop.city.name }},
        {{ computedShop.address_description }}
      </div>
    </div>

    <!--Minimum Order-->
    <!-- At the moment minimum order is not configurable on the backend-->

    <!--Description-->
    <div class='row description' v-if="computedShop !== null && computedShop.description !== null">
      <div class="col-12"><hr></div>
      <div class="col-12 font-weight-bold font-size-md color-secondary">
        {{ $t("shops.description") }}
      </div>
      <div class="col-12 color-secondary pt-2">
        {{ computedShop.description }}
      </div>
    </div>

    <!--Payment Methods-->
    <div class='payment_methods' v-if="computedShop !== null">
      <hr>
      <div class="row">
        <div class="col-auto col-md-12 font-weight-bold font-size-md color-secondary">
          {{ $t("shops.payment_methods_label") }}
        </div>
        <div class="col-auto col-md-12 row">
          <div class="col-auto Aligner items-left"
               v-if="computedShop.payment_gateway === 'both' || computedShop.payment_gateway === 'bank'">
            <img class='icon pe-1' :src=" require(`@/assets/payment_methods/bank_black.svg`)"/>
            {{ $t("shops.payment_methods.bank") }}
          </div>
          <div class="col-auto Aligner items-left"
               v-if="computedShop.payment_gateway === 'both' || computedShop.payment_gateway === 'cash'">
            <img class='icon pe-1' :src=" require(`@/assets/payment_methods/cash_black.svg`)"/>
            {{ $t("shops.payment_methods.cash") }}
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    shop: {
      type: Object,
      default() {
        return null
      }
    },
  },
  name: "ProductShopSidebar",
  methods: {
    subscribeToShop() {
      return new Promise((resolve, reject) => {
        window.axios
            .post(
                window.Routes.getRoute("shop_follow_toggle", {
                  shop_id: this.computedShop.id,
                })
            )
            .then(
                (response) => {
                  resolve(response.data.data);
                  this.computedShop.subscribed = !this.computedShop.subscribed;
                  console.log(response.data.success);
                },
                (error) => {
                  reject(error.response.data.errors);
                  console.log(error.response.data.errors);
                }
            );
      });
    }
  },
  computed: {
    ...mapGetters(['access_token']),
    computedShop() {
      return this.shop;
    },
    distanceToShop() {
      if (this.computedShop != null) {
        return parseFloat(this.computedShop.distance).toFixed(2);
      }

      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
#shopSidebar {
  width: 280px;
  border-left: 1px solid map-get($defaults, "border-color");
  padding-left: map-get($spacings, "medium");
  padding-bottom: map-get($spacings, "medium");

  .logo {
    width: 40px;
    background-size: cover;
    border-radius: 40px;
    overflow: hidden;
    border: 1px solid map-get($defaults, "border-color");

    .skeleton_loader {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    a {
      color: map-get($colors, "black");
    }
  }

  .store-navigation {
    margin-top: map-get($spacings, "default");


    .store-subscribe {
      margin-left: map-get($spacings, "small");
    }
  }


  .rating {
    .fa-circle {
      font-size: 4px;
    }
  }

  .payment_methods {
    .icon {
      width: 24px;
    }
  }
}

@include media-breakpoint-down(lg) {
  #shopSidebar {
    min-width: 100%;
    width: auto;
    border-left: 0;
    background-color: map-get($colors, "lightGray");
    border-top: 1px solid map-get($defaults, "border-color");
    bottom: 1px solid map-get($defaults, "border-color");
    padding-top: map-get($spacings, "default");
    margin-left: -(map-get($spacings, 'default'));
    margin-right: -(map-get($spacings, 'default'));
    padding-left: (map-get($spacings, 'default'));
    padding-right: (map-get($spacings, 'default'));

    .name {
      text-decoration: underline;
      padding-left: map-get($spacings, "small");
    }

    hr {
      margin-top: map-get($spacings, "small");
      margin-bottom: map-get($spacings, "small");
    }

    .store-navigation {
      margin-top: 3px;
      margin-bottom: 0;

      .store-visit {
        display: none;
      }

      .store-subscribe {
        margin-left: 0;
      }
    }

    .description {
      display: none;
    }

    .payment_methods {
      width: 100%;
      float: left;

      .icon {
        width: 24px;
      }
    }
  }
}
</style>

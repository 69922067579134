<template>
  <div class="w-100 float-left">
    <!-- Check if shop is opened -->
    <div v-if="product_ready === true && product.shop.opened === false">
      <alert type="info">
        <div class="font-weight-bold">
          {{ $t("shops.closed_title") }}
        </div>
        {{ $t("shops.closed_subtitle") }}
      </alert>
    </div>

    <!-- Check if shop prescription is require -->
    <div v-if="product_ready === true && product.prescription_required === true">
      <alert type="info">
        <div class="font-weight-bold">
          {{ $t("products.prescription_required_title") }}
        </div>
        {{ $t("products.prescription_required_subtitle") }}
      </alert>
    </div>

    <!-- Check if shop prescription is require -->
    <div v-if="product_ready === true && product.delivery_price === -1">
      <alert type="info">
        <div class="font-weight-bold">
          {{ $t("products.errors.delivery_not_available.title") }}
        </div>
        {{ $t("products.errors.delivery_not_available.body") }}
      </alert>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert";

export default {
  name: "ProductAlertMessages",
  components: {Alert},
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      }
    },
    product_errors: {
      type: Object,
      default: () => {
        return {
          "errors": []
        };
      }
    },
    product_ready: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>

</style>

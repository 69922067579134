<template>
  <div ref="mainPackageNav" class="package-nav">
    <div class="description">
      <div class="add-to-package-custom">
        <div class="title font-size-lg">
          {{ $t("packages.add.to_package") }}
        </div>
        <div @click="closeNav" class="close-nav-holder">
          <div class="close-nav"></div>
        </div>
      </div>
      <div class="text font-size-md">
        {{ $t("packages.add.select_or_create") }}
      </div>
    </div>

    <div
      @click="showPackageForm"
      class="new-package-holder"
      :class="field + ' ' + 'remove'"
    >
      <div class="text">{{ $t("packages.add.new") }}</div>
      <div class="add-new package">
        <div class="package-icon-holder">
          <div class="package-icon"></div>
        </div>
      </div>
    </div>
    <div class="package-input-holder" :class="field + ' ' + 'show'">
      <Form @submit="saveDetails" class="new-package-add">
        <div class="d-flex Aligner w-100 field-holder">
          <div class="d-flex Aligner w-100 field">
            <Field
              name="name"
              type="text"
              class="input"
              rules="required"
              :placeholder="$t('packages.add.create.modal.label')"
              v-model="modelValue"
            />

            <button type="submit" class="btn btn-primary">
              {{ $t("global.save") }}
            </button>
          </div>
          <div @click="closeInput" class="icon-close"></div>
        </div>
        <ErrorMessage name="name" />
        <validation-error name="name" class="errorMessage" />
      </Form>
    </div>
    <div @scroll="$emit('scrolling', $event)" class="packages">
      <package-template
        @click="saveProduct(single_package)"
        v-for="single_package in packages"
        :key="single_package.id"
        class="pb-3 border-bottom-color cursor-pointer"
      >
        <package-color :color="single_package.name" />
        <package-card-content>
          <PackageBadge
            class="d-none d-sm-block"
            :enabled="single_package.status"
          />
          <div class="package-activity">
            <div class="package-name font-size-md dotdotdot lines-2">
              {{ single_package.name }}
            </div>
          </div>
          <div class="products font-size-default">
            {{ $t("packages.index.products") }}
            <div class="numer">
              {{ single_package?.products?.length }}
            </div>
          </div>
        </package-card-content>
        <package-icon><div class="icon d-none d-sm-block"></div> </package-icon>
      </package-template>
      <loading :loading="!loading_ready" />
    </div>
  </div>
  <div ref="packageNavLayer" class="package-nav-layer"></div>

  <!-- Create Modal -->
  <confirm-modal
    modalDialogClasses="modal-notice"
    :title="$t('packages.add.create.title')"
    declinedBtnClass="btn-default"
    :closeButton="true"
    :showButtons="false"
  >
    <template #body>
      <div class="body-holder font-size-md mt-3">
        {{ $t("packages.add.create.body") }}
      </div>
      <Form v-on:submit="saveDetails" class="new-package-add">
        <div class="form-container">
          <div class="inputContainer">
            <label
              class="labelStyle"
              :class="{ inFocusInput: isFocused }"
              :for="id"
            >
              {{ $t("packages.add.create.modal.label") }}
            </label>

            <Field
              rules="required"
              :id="id"
              class="inputStyle font-size-md"
              @focus="onFocus"
              type="text"
              v-model="modelValue"
              @blur="onBlur"
              name="name"
            />
          </div>
          <validation-error name="name" class="errorMessage" />
          <ErrorMessage name="name" class="errorMessage" />
          <button type="submit" class="btn btn-primary p-2 mt-3">
            {{ $t("packages.add.create.modal.button") }}
          </button>
        </div>
      </Form>
      <button
        ref="closeOnSubmit"
        data-bs-dismiss="modal"
        type="text"
        class="btn btn-default w-100"
      >
        {{ $t("global.cancel") }}
      </button>
    </template>
    <button ref="addnewPackage" class="d-none"></button>
  </confirm-modal>
  <!-- Update Modal -->
  <confirm-modal
    modalDialogClasses="modal-notice"
    :title="$t('packages.update.modal.title')"
    declinedBtnClass="btn-default"
    :closeButton="true"
    :showButtons="false"
    :modalWrapper="null"
  >
    <template #body>
      <div class="body-holder font-size-md mt-3">
        {{ $t("packages.update.modal.body") }}
      </div>
      <Form v-on:submit="updatePackage" class="new-package-add">
        <div class="form-container">
          <div class="inputContainer customise">
            <div class="input-holder">
              <form-check-input
                class="customise"
                type="radio"
                name="recurring_type"
                checked="true"
                value="onetime"
                @changed="recurringTypeChanged('onetime')"
              >
                {{ $t("packages.update.modal.fields.specific") }}
              </form-check-input>

              <div
                v-if="created_package.recurring_type == 'onetime'"
                class="datetime holder"
              >
                <Field
                  class="inputStyle date font-size-md"
                  name="date"
                  type="date"
                  :min="minDate"
                  rules="required"
                  v-model="created_package.date"
                />

                <validation-error name="date" class="errorMessage" />
                <ErrorMessage name="date" class="errorMessage" />
                <Field
                  class="inputStyle time font-size-md"
                  name="time"
                  type="time"
                  rules="required"
                  v-model="created_package.time"
                />

                <validation-error name="time" class="errorMessage" />
                <ErrorMessage name="time" class="errorMessage" />
              </div>
            </div>

            <div class="input-holder">
              <form-check-input
                class="customise"
                type="radio"
                name="recurring_type"
                value="weekly"
                @changed="recurringTypeChanged('weekly')"
              >
                {{ $t("packages.update.modal.fields.weekly") }}
              </form-check-input>
              <div
                v-if="created_package.recurring_type == 'weekly'"
                class="datetime holder"
              >
                <Field
                  class="inputStyle time font-size-md"
                  name="time"
                  type="time"
                  rules="required"
                  v-model="created_package.time"
                />

                <validation-error name="time" class="errorMessage" />
                <ErrorMessage name="time" class="errorMessage" />
                <DayDropDown
                  :recurring_type="created_package.recurring_type"
                  @showHide="showHide"
                  :hidden="hidden"
                  :Days="WEEK"
                />
              </div>
            </div>
            <div class="input-holder">
              <form-check-input
                class="customise"
                type="radio"
                name="recurring_type"
                @changed="recurringTypeChanged('monthly')"
                value="monthly"
              >
                {{ $t("packages.update.modal.fields.monthly") }}
              </form-check-input>
              <div
                v-if="created_package.recurring_type == 'monthly'"
                class="datetime holder"
              >
                <Field
                  class="inputStyle time font-size-md"
                  name="time"
                  type="time"
                  rules="required"
                  v-model="created_package.time"
                />

                <validation-error name="time" class="errorMessage" />
                <ErrorMessage name="time" class="errorMessage" />
                <DayDropDown
                  :recurring_type="created_package.recurring_type"
                  @showHide="showHide"
                  :hidden="hidden"
                  :Days="DAY"
                />
              </div>
            </div>
          </div>
          <validation-error name="name" class="errorMessage" />
          <ErrorMessage name="name" class="errorMessage" />
          <button type="submit" class="btn btn-primary p-2 mt-3">
            {{ $t("packages.update.modal.buttons.save") }}
          </button>
        </div>
      </Form>
      <button
        @click="saveLater"
        ref="closeOnUpdate"
        data-bs-dismiss="modal"
        type="text"
        class="btn btn-default w-100"
      >
        {{ $t("packages.update.modal.buttons.cancel") }}
      </button>
    </template>
    <button ref="updatePackage" class="d-none"></button>
  </confirm-modal>

  <!--  Product Has been added to cart-->
  <action-modal
    v-model="added_to_package_modal"
    :timeout="10000"
    v-if="product_ready === true"
    :header_title="$t('products.added_to_package_modal.header_title')"
    :header_subtitle="$t('products.added_to_package_modal.header_subtitle')"
    :image="window.Routes.getImage(product.images[0], 'preview')"
  >
    <!-- Main -->
    <template v-slot:main>
      <div class="title">{{ product.name }}</div>
      <div class="price font-weight-bold font-size-md">
        {{ formatPrice(product.currency, total_price) }}
      </div>
    </template>

    <!-- Footer -->
    <template v-slot:footer>
      <router-link :to="'/packages/' + packageId" class="btn btn-primary w-100"
        >{{ $t("products.added_to_package_modal.action_label") }}
      </router-link>
    </template>
  </action-modal>
</template>

<script>
import PackageTemplate from "@/components/Packages/parts/PackageTemplate.vue";
import PackageColor from "@/components/Packages/parts/PackageColor.vue";
import PackageCardContent from "@/components/Packages/parts/PackageCardContent.vue";
import PackageIcon from "@/components/Packages/parts/PackageIcon.vue";
import PackageBadge from "@/components/Packages/parts/PackageBadge.vue";
import formatPrice from "@/includes/currency";
import {
  WEEK,
  DAY,
  formatDateTime,
  formatTimeToUtc,
  formatWeekDay,
  formatDay,
} from "@/includes/formatDateTime";
import { Field, Form, ErrorMessage } from "vee-validate";
import ValidationError from "@/components/ValidationError.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import { mapActions, mapGetters } from "vuex";
import FormCheckInput from "@/components/FormCheckInput";
import ActionModal from "@/components/ActionModal";
import DayDropDown from "@/views/Packages/partials/DayDropDown.vue";
import Loading from "@/components/Loading.vue";
import CustomInput from "@/mixins/CustomInput";

export default {
  name: "PackageNav",
  mixins: [CustomInput],
  props: {
    product: {},
    total_price: null,
    loading_ready: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["scrolling"],
  components: {
    PackageTemplate,
    PackageColor,
    PackageCardContent,
    PackageIcon,
    PackageBadge,
    Form,
    Field,
    ErrorMessage,
    ValidationError,
    ConfirmModal,
    FormCheckInput,
    ActionModal,
    DayDropDown,
    Loading,
  },
  data() {
    return {
      modelValue: "",
      packageId: null,
      added_to_package_modal: false,
      mainPackageNav: null,
      product_ready: false,
      field: "",
      package: {
        recurring_type: "",
      },
      old_packages: [],
      minDate: this.getDate(),
      WEEK: WEEK,
      DAY: DAY,
      hidden: "hidden",
    };
  },
  computed: {
    ...mapGetters({
      created_package: "getPackage",
      package_product: "getPackageProduct",
      packages: "getPackages",
      day: "getDay",
      week: "getWeek",
    }),
  },
  methods: {
    ...mapActions([
      "setPackage",
      "setProduct",
      "packageUpdate",
      "resetPackages",
    ]),
    getDate() {
      let date = new Date();
      let day = date.getDate().toString();
      let month = (date.getMonth() + 1).toString();
      let year = date.getFullYear().toString();
      if (day.length == 1) {
        day = "0" + day;
      }
      if (month.length == 1) {
        month = "0" + month;
      }
      return year + "-" + month + "-" + day;
    },
    recurringTypeChanged(recurring_type) {
      this.created_package.recurring_type = recurring_type;
    },
    showPackageForm() {
      const BOOTSRAP_SM = 576;
      if (window.innerWidth <= BOOTSRAP_SM) {
        this.field = "field";
      } else {
        this.$refs.addnewPackage.click();
        this.$refs.mainPackageNav.classList.remove("margin-0");
        this.$refs.packageNavLayer.classList.remove("show");
        this.field = "";
        this.modelValue = "";
      }
    },
    closeNav() {
      this.$refs.mainPackageNav.classList.remove("margin-0");
      this.$refs.packageNavLayer.classList.remove("show");
      this.field = "";
      this.modelValue = "";
    },
    showHide() {
      if (this.hidden == "rotate") {
        this.hidden = "hidden";
      } else {
        this.hidden = "rotate";
      }
    },
    formatPrice(currency, price) {
      return formatPrice(currency, price);
    },
    saveDetails: async function (e) {
      this.$refs.closeOnSubmit.click();
      this.modelValue = "";
      this.package.shop_id = this.product.shop.id;
      this.package.name = e.name;
      this.package.preferred_currency = this.product.currency;
      this.old_packages = this.packages.length;
      await this.setPackage(this.package);
      this.created_package.date = this.minDate;
      if (this.packages.length > this.old_packages) {
        //Means new package created and added to list
        //Open new Modal, Update Package modal
        this.$refs.updatePackage.click();
      }
    },
    updatePackage: async function (e) {
      this.$refs.closeOnUpdate.click();
      await this.prepareUpdateData(e);
      this.packageUpdate(this.created_package);
    },
    async prepareUpdateData(formData) {
      this.created_package.address_id = this.created_package.address.id;
      this.created_package.status = true;
      if (this.created_package.recurring_type == "onetime") {
        this.created_package.datetime = formatDateTime(
          formData.date,
          formData.time
        );
      } else if (this.created_package.recurring_type == "weekly") {
        let formatTimeToUtc_var = formatTimeToUtc(formData.time);
        this.created_package.time = formatTimeToUtc_var.utc_time;
        let difference = formatTimeToUtc_var.utc_day - formatTimeToUtc_var.day;
        this.created_package.day = formatWeekDay(
          difference,
          this.week.dayValue
        );
      } else if (this.created_package.recurring_type == "monthly") {
        let formatTimeToUtc_var = formatTimeToUtc(formData.time);
        this.created_package.time = formatTimeToUtc_var.utc_time;
        let difference = formatTimeToUtc_var.utc_day - formatTimeToUtc_var.day;
        this.created_package.day = formatDay(difference, this.day.dayValue);
      }
    },
    closeInput() {
      this.field = "";
    },
    saveLater: async function () {
      //add Package Products
      await this.saveProduct(this.created_package);
    },
    saveProduct: async function (packageObject) {
      this.package_product.package_id = packageObject.id;
      await this.setProduct(this.package_product);
      this.$refs.mainPackageNav.classList.remove("margin-0");
      this.$refs.packageNavLayer.classList.remove("show");
      this.product_ready = true;
      this.added_to_package_modal = true;
      this.packageId = packageObject.id;
    },
  },
  watch: {
    $route() {
      this.resetPackages();
    },
  },
};
</script>

<style lang="scss" scoped>
//Package Navigation
.package-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: map-get($spacings, "large");
  top: 0;
  bottom: 0;
  right: 0;
  max-width: 360px;
  width: 100%;
  z-index: 1001;
  margin-right: -360px;
  transition: margin 100ms;
  padding: map-get($spacings, "xl") map-get($spacings, "medium");

  @include media-breakpoint-down(sm) {
    margin-right: 0px;
    max-width: 100%;
    max-height: 50%;
    transition: top 100ms;
    top: 100%;
  }
  background-color: map-get($colors, "white");
  box-shadow: -3px 3px 15px #00000029;
  border-radius: 8px 0px 0px 8px;
  &.margin-0 {
    margin-right: 0;
    @include media-breakpoint-down(sm) {
      border-radius: 8px 8px 0px 0px;
      top: 50%;
    }
  }
  .description {
    display: flex;
    flex-direction: column;
    gap: map-get($spacings, "xsmall");
    .add-to-package-custom {
      font-weight: bold;
      color: map-get($colors, "black");
      display: flex;
      .title {
        width: 100%;
      }
      .close-nav-holder {
        cursor: pointer;
        width: 100%;
        max-width: 20px;
        .close-nav {
          width: 100%;
          height: 100%;
          background-image: url("../../../assets/close.svg");
          background-repeat: no-repeat;
        }
      }
    }
  }

  .new-package-holder {
    cursor: pointer;
    background-color: map-get($colors, "secondary");
    max-height: 50px;
    min-height: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      flex-direction: row-reverse;
      background-color: map-get($colors, "white");
      gap: map-get($spacings, "medium");
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='%23d5d5d5' stroke-width='2' stroke-dasharray='10%2c 9' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 11px;
    }
    margin-top: map-get($spacings, "small");
    border-radius: 8px;
    padding: 0 map-get($spacings, "default");
    .text {
      width: 100%;
      color: map-get($colors, "white");
      @include media-breakpoint-down(sm) {
        color: map-get($colors, "secondary");
      }
    }
    .add-new.package {
      display: flex;
      align-items: center;
      width: 100%;

      @include media-breakpoint-down(sm) {
        max-width: 30px;
      }
      .package-icon-holder {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        @include media-breakpoint-down(sm) {
          max-width: 30px;
          justify-content: flex-start;
        }
        height: 100%;
        .package-icon {
          background-image: url("../../../assets/package/add.svg");
          background-repeat: no-repeat;
          max-width: 30px;
          min-height: 25px;
          width: 100%;
          height: 100%;
        }
      }
    }
    &.field.remove {
      display: none;
    }
  }
  .package-input-holder {
    display: none;
    &.field.show {
      display: flex;
      width: 100%;
      .new-package-add {
        display: flex;
        height: 100%;

        .field-holder {
          gap: map-get($spacings, "small");
          .field {
            padding: map-get($spacings, "xsmall");
            border: 1px solid map-get($colors, "bordering-gray");
            border-radius: 8px;

            .input {
              height: 100%;
              width: 100%;
              border: 0px;
              padding: map-get($spacings, "xsmall");
              &:focus {
                outline: none;
              }
              &::placeholder {
                display: flex;
                align-items: center;
                color: map-get($colors, "secondary");
                font-size: 16px;
              }
            }
          }
          .icon-close {
            background-image: url("../../../assets/close.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
        span {
          color: red;
          padding-top: map-get($spacings, "xsmall");
        }
      }
    }
  }
  .packages {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: map-get($colors, "bordering-gray");
    }
    .package-activity {
      .package-name {
        font-weight: 700;
        @include media-breakpoint-down(sm) {
          font-weight: 500;
        }
      }
      .products {
        display: flex;
        gap: 2px;
        @include media-breakpoint-down(sm) {
          display: none;
        }
        .number {
          font-weight: 700;
        }
      }
    }
    .icon {
      width: 10px;
      height: 10px;
      background-image: url("../../../assets/nav_arrow_right.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
.package-nav-layer {
  &.show {
    @include media-breakpoint-down(sm) {
      background-color: map-get($colors, "black");
      opacity: 0.8;
      position: fixed;
      inset: 0;
      z-index: 1000;
    }
  }
}
.new-package-add {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .inputBox {
    max-width: 310px;
    width: 100%;
    margin-bottom: 20px;
    padding-top: map-get($spacings, "medium");
  }
  .form-container {
    display: flex;
    flex-direction: column;
    gap: map-get($spacings, "xsmall");
    .inputContainer {
      flex-direction: column;

      width: 100%;
      height: auto;
      position: relative;
      display: flex;
      border-bottom: 1px solid map-get($colors, "gray");
      padding-top: map-get($spacings, "medium");
      &.customise {
        gap: map-get($spacings, "medium");
        border-bottom: 0px;
      }
      .inputStyle {
        cursor: pointer;
        color: map-get($colors, "black");
        border: none;
        width: 100%;
        height: 30px;
        line-height: 16px;
        background-color: transparent;
        &:focus {
          border: none;
          outline: none;
        }
        &:active {
          background-color: transparent;
        }
      }

      .labelStyle {
        position: absolute;
        bottom: 5px;
        font-size: 16px;
        transition: ease-in-out 0.2s;
        color: map-get($colors, "secondary");
        font-weight: 400;
      }

      .inFocusInput {
        bottom: 26px;
        font-size: 14px;
        transition: ease-in-out 0.2s;
      }
      .input-holder {
        border-bottom: 1px solid rgb(213, 213, 213, 0.5);
        padding-bottom: map-get($spacings, "medium");
        &:last-child {
          border-bottom: 0px;
        }
        .datetime.holder {
          padding-top: map-get($spacings, "medium");
          display: flex;
          flex-direction: column;
          gap: map-get($spacings, "default");
          .inputStyle {
            border: 1px solid map-get($colors, "bordering-gray");
            border-radius: 8px;
            padding: 0 map-get($spacings, "small");
            height: 50px;
            &::-webkit-calendar-picker-indicator {
              // background: orange;
              background-size: cover;
            }
            &.time {
              &::-webkit-calendar-picker-indicator {
                // background: orange;
                background-size: 129% 129%;
              }
            }
          }
        }
      }
    }
    .errorMessage {
      color: red;
      left: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .new-package-add {
    .inputBox {
      max-width: 100%;
    }
  }
}
</style>
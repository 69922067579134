export default {
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    checkAgreement() {
      if (
        localStorage.getItem("modalAgreement") == null &&
        sessionStorage.getItem("sessionModalAgreement") == null
      ) {
        this.$refs.packageAgreement.click();
        return false;
      }
      return true;
    },
    acceptAgreement() {
      if (this.$refs.modalAgreement.$refs.checkBoxValue.checked == true) {
        localStorage.setItem("modalAgreement", true);
      } else {
        sessionStorage.setItem("sessionModalAgreement", true);
      }
    },
  },
};

<template>
  <div
      class="seperator extras"
      v-if="product_ready === true && product_extras.length !== 0"
  >
    <div class="text-uppercase color-secondary">
      {{ $t("global.add") }} {{ $t("products.extras") }}
    </div>
    <div class="body">
      <!-- Each Extra -->
      <div
          class="row extra g-0"
          v-for="product_extra in product_extras"
          :key="product_extra.id"
      >
        <div
            class="col-auto Aligner items-left image"
            v-if="product_extra.image != null"
        >
          <div
              class="ratio"
              :style="
                      'background-image: url(' +
                      window.Routes.getImage(product_extra.image, 'preview') +
                      ');'
                    "
          ></div>
        </div>
        <div class="col font-weight-bold Aligner items-left name">
          {{ product_extra.name }}
        </div>
        <div class="col-md-auto Aligner items-right controls">
          <div class="row g-0">
            <div class="col-auto order-2 order-md-1 Aligner items-right pe-0 pe-md-2">
              {{ formatPrice(product_extra.currency, product_extra.price) }}
            </div>
            <div class="col-auto order-1 order-md-2 pe-2 pe-md-0">
              <input-counter
                  size="small"
                  :min="0"
                  :step="1"
                  :input_disabled="true"
                  v-model="extras[product_extra.id]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import formatPrice from "@/includes/currency";
import InputCounter from "@/components/CustomInputs/InputCounter";

export default {
  name: "ProductExtras",
  components: {InputCounter},
  props: {
    modelValue: {
      type: Object
    },
    product_extras: {
      type: Object,
      default: () => {
        return {};
      }
    },
    product_ready: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      extras: {}
    }
  },
  methods: {
    formatPrice(currency, price) {
      return formatPrice(currency, price);
    },
  },
  watch: {
    modelValue: function () {
      this.extras = this.modelValue;
    },
    extras: {
      handler() {
        //Calculate total price
        this.$emit('update:modelValue', this.extras)
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss">
.extras {
  .extra {
    margin-bottom: map-get($spacings, "small");

    .image {
      margin-right: map-get($spacings, "small");

      & > .ratio {
        width: 40px;
        background-position: center;
        background-size: cover;
        border-radius: map-get($defaults, "border-radius");
        border: 1px solid map-get($defaults, "border-color");
      }
    }

    .controls {
      padding-left: map-get($spacings, "default");

      .input-counter {
        width: 100px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .extras {
    .extra {
      margin-bottom: map-get($spacings, 'default');
      .controls {
        padding-left:0;
        margin-top: 0px;
        margin-left:50px;
        justify-content: flex-start;
      }
    }
  }
}
</style>

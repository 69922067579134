<template>
  <div class="seperator quantity">
    <div class="text-uppercase color-secondary">
      {{ $t("products.quantity") }}
    </div>
    <div class="body">
      <input-counter
          v-if="stock !== 0"
          :input_disabled="true"
          :min="1"
          :max="max_product_quantity"
          :step="1"
          v-model="quantity"
      />
      <alert v-if="stock === 0" :dismissible="false">
        {{ $t("products.out_of_stock") }}
      </alert>
    </div>
  </div>

</template>

<script>
import InputCounter from "@/components/CustomInputs/InputCounter";
import Alert from "@/components/Alert";

export default {
  name: "ProductQuantity",
  components: {Alert, InputCounter},
  props: {
    modelValue: {
      type: Number
    },
    product_ready: {
      type: Boolean,
      default: false
    },
    stock: {
      type: Number
    }
  },
  data() {
    return {
      quantity: 1
    }
  },
  computed: {
    max_product_quantity: function () {
      if (this.product_ready === true) {
        if (this.stock === -1) {
          return null;
        }

        return this.stock;
      }

      return null;
    },
  },
  watch: {
    //Get value from v-bind
    modelValue: function () {
      this.quantity = this.modelValue;
    },
    //Set value to v-bind
    quantity: {
      handler() {
        //Calculate total price
        this.$emit('update:modelValue', this.quantity)
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss">
.quantity {
  .input-counter {
    &.input-group {
      width: 150px;

      .form-control {
        width: 50px !important;
      }
    }
  }
}
</style>

<template>
  <div class="price_wrapper">
    <div class="price_holder" v-if="product_ready !== true">
      <div class="skeleton">
        <div class="line skeleton_loader thick w-40 mb-0"></div>
      </div>
    </div>
    <div
        class="price_holder Aligner items-left"
        v-if="product_ready === true"
    >
      <span class="price font-size-xl font-weight-bold ps-1">
        {{ formated_price }}
      </span>

      <span
          class="font-size-sm color-secondary text-decoration-line-through font-weight-bold"
          v-if="product.discount.discount_type !== 'none'"
      >
        {{ formated_original_price }}
      </span>

      <span
          class="discount badge bg-twiks color-black font-size-sm"
          v-if="product.discount.discount_type !== 'none'"
      >
        - {{ product.discount.discount_value_formated }}
      </span>
    </div>
  </div>
</template>

<script>
import formatPrice from "@/includes/currency";

export default {
  name: "ProductPrice",
  props: {
    product_ready: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object
    },
    selected_variant_id: {
      type: Number,
      default: null
    }
  },
  computed: {
    variant: function () {
      for (const variant_type of this.product.product_variants) {
        for (const variant of variant_type.variants) {
          if (variant.id === this.selected_variant_id) {
            //ATTENTION: At the moment backend support only one variant selected
            return variant;
          }
        }
      }
      return null;
    },
    formated_price: function () {
      let product_price = this.product.price;
      //Check if variant is selected
      if (this.variant != null) {
        product_price += this.variant.extra_price;
      }
      return formatPrice(this.product.currency, product_price);
    },
    formated_original_price: function () {
      let product_price =
          this.product.price + this.product.discount.discount_amount;
      //Check if variant is selected
      if (this.variant != null) {
        product_price += this.variant.extra_price;
      }
      return formatPrice(this.product.currency, product_price);
    }
  },
  methods: {
    formatPrice(currency, price) {
      return formatPrice(currency, price);
    },
  },
}
</script>

<style lang="scss" scoped>
.price_wrapper {
  .price_holder {
    width: 100%;
    float: left;
    border-top: 1px solid map-get($defaults, "border-color");
    border-bottom: 1px solid map-get($defaults, "border-color");
    padding-top: map-get($spacings, "small");
    padding-bottom: map-get($spacings, "small");
    margin-top: map-get($spacings, "small");
    margin-bottom: map-get($spacings, "small");
    flex-wrap: wrap;

    & > span {
      margin-right: map-get($spacings, "small");
    }
  }
}
</style>

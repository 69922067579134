<template>
  <div id="productShow">
    <div class="container">
      <div class="row">
        <div class="col-12 d-block d-md-none mt-3 mb-3">
          <!-- Titlte -->
          <div class="title font-size-xl">
            {{ product.name }}

            <div class="skeleton" v-if="product.name === null">
              <div class="line skeleton_loader thick w-70"></div>
            </div>
          </div>
        </div>

        <!-- Gallery -->
        <div class="col-md-5 col-lg-4">
          <product-gallery :images="product !== null ? product.images : []"/>
        </div>

        <!-- Basic Details -->
        <div class="col-md basic_details">
          <!-- Title -->
          <div class="title font-size-xl d-none d-md-block">
            {{ product.name }}

            <div class="skeleton" v-if="product.name === null">
              <div class="line skeleton_loader thick w-70"></div>
            </div>
          </div>

          <!-- Rating -->
          <product-rating
              :product_ready="product_ready"
              :rating="product.rating"
          />

          <!-- Price -->
          <product-price
              :product_ready="product_ready"
              :product="product"
              :selected_variant_id="cart_data.variant"
          />

          <!-- Variants -->
          <product-variants
              v-model="cart_data.variant"
              :product_ready="product_ready"
              :product_variants="product.product_variants"
          />

          <!-- Extras -->
          <product-extras
              v-model="cart_data.product_extras"
              :product_ready="product_ready"
              :product_extras="product.product_extras"
          />

          <!-- Quantity -->
          <product-quantity
              v-model="cart_data.quantity"
              :product_ready="product_ready"
              :stock="product.stock"
          />

          <!-- Shipping method -->
          <ProductDeliveryType
              v-model="cart_data.delivery_type"
              :product="product"
              :product_ready="product_ready"
          />

          <!-- Alert Messages -->
          <ProductAlertMessages
              :product_ready="product_ready"
              :product="product"
              :product_errors="product_errors"
          />

          <!-- Controls -->
          <div
              class="seperator controls"
              v-if="
              product_ready === true &&
              product.stock !== 0 &&
              product.delivery_price !== -1
            "
          >
            <div class="row">
              <div :class="[access_token ? 'col-md-6' : 'col-12']">
                <button
                    class="btn btn-primary w-100 add-to-cart"
                    @click="addToCart"
                >
                  <span class="label">{{ $t("products.add_to_cart") }}</span>
                  <span class="label font-size-sm"
                  >{{
                      product.length != 0
                          ? formatPrice(product.currency, total_price)
                          : ""
                    }}
                  </span>
                </button>
              </div>
              <AddToPackage
                  v-if="product_ready === true && access_token !== null"
                  :total_price="total_price"
                  :product="product"
                  :cart_data="cart_data"
              />
            </div>
          </div>
        </div>

        <!-- Shop Info -->
        <div class="col-lg-auto">
          <product-shop-sidebar
              :shop="product_ready ? product.shop : null"
          ></product-shop-sidebar>
        </div>

        <!-- Product Details -->
        <div class="col-12">
          <product-details :product="product" :product_ready="product_ready"/>
        </div>
      </div>
    </div>

    <!--  Product Has been added to cart-->
    <action-modal
        v-model="added_to_cart_modal"
        :timeout="10000"
        v-if="product_ready === true"
        :header_title="$t('products.added_to_cart_modal.header_title')"
        :header_subtitle="$t('products.added_to_cart_modal.header_subtitle')"
        :image="window.Routes.getImage(product.images[0], 'preview')"
    >
      <!-- Main -->
      <template v-slot:main>
        <div class="title">{{ product.name }}</div>
        <div class="price font-weight-bold font-size-md">
          {{ formatPrice(product.currency, cart_total_price) }}
        </div>
      </template>

      <!-- Footer -->
      <template v-slot:footer>
        <router-link to="/cart" class="btn btn-primary w-100"
        >{{ $t("products.added_to_cart_modal.action_label") }}
        </router-link>
      </template>
    </action-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {calculatePrice} from "@/includes/cart";
import formatPrice from "@/includes/currency";
import ProductGallery from "@/views/Products/partials/ProductGallery.vue";
import ProductShopSidebar from "@/views/Products/partials/ProductShopSidebar";
import ProductDeliveryType from "@/views/Products/partials/ProductDeliveryType";
import ProductExtras from "@/views/Products/partials/ProductExtras";
import ProductVariants from "@/views/Products/partials/ProductVariants";
import ProductQuantity from "@/views/Products/partials/ProductQuantity";
import ProductDetails from "@/views/Products/partials/ProductDetails";
import ProductPrice from "@/views/Products/partials/ProductPrice";
import ProductRating from "@/views/Products/partials/ProductRating";
import ProductAlertMessages from "@/views/Products/partials/ProductAlertMessages";
import ActionModal from "@/components/ActionModal";
import AddToPackage from "@/views/Products/partials/AddToPackage.vue";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  components: {
    ActionModal,
    ProductAlertMessages,
    ProductRating,
    ProductPrice,
    ProductDetails,
    ProductQuantity,
    ProductVariants,
    ProductExtras,
    ProductDeliveryType,
    ProductShopSidebar,
    ProductGallery,
    AddToPackage,
  },
  name: "ProductsShow",
  setup(){
    const route = useRoute();
    const {t} = useI18n() // use as global scope
    useMeta({
      title: route.query.title ?? t("products.show.page_title"),
    })
  },
  data() {
    return {
      added_to_cart_modal: false,
      product_ready: false,
      product: [],
      product_errors: [],
      cart_data: {
        quantity: 1,
        delivery_type: "by_driver",
        product_extras: {},
        variant: null,
      },
      total_price: 0,
      cart_total_price: 0, //Last total price added to cart

      meta: {},
      page: 1,
      shop_id: null,
      loading_ready: true,
    };
  },
  computed: {
    ...mapGetters(['access_token']),
    product_id: function () {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    ...mapActions(["add_to_cart", "setErrors"]),
    async getProduct() {
      //Clean data
      this.product_ready = false;
      this.product = [];
      this.product_errors = [];
      return new Promise((resolve, reject) => {
        window.axios
            .get(
                window.Routes.getRoute("product_show", {
                  product_id: this.product_id,
                }), {
                  params: prepareGuestAddressParams(),
                }
            )
            .then(
                (response) => {
                  resolve(response.data.data);
                  this.product_ready = true;
                  this.product = response.data.data;
                  this.product_errors = response.data.meta;
                },
                (error) => {
                  this.setErrors(error.response.data.errors);
                  reject(error.response.data.errors);
                }
            );
      });
    },
    formatPrice(currency, price) {
      return formatPrice(currency, price);
    },
    clearUserInputs() {
      this.cart_data = {
        quantity: 1,
        product_extras: {},
        delivery_type: "by_driver",
        variant: null,
      };
      this.cart_data.product_id = this.product.id;
      this.setPrimaryVariants();
    },
    addToCart() {
      this.cart_total_price = this.total_price;
      this.add_to_cart({
        product: this.product,
        cart_data: this.cart_data,
      });
      this.added_to_cart_modal = true;
      this.clearUserInputs();
    },
    setPrimaryVariants() {
      //Get default variants
      for (const variant_type of this.product.product_variants) {
        for (const variant of variant_type.variants) {
          if (variant.primary === true) {
            //ATTENTION: At the moment backend support only one variant selected
            this.cart_data.variant = variant.id;
          }
        }
      }
    },
    loadProduct() {
      //Get Product
      this.getProduct().then(
          () => {
            this.cart_data.product_id = this.product.id;
            this.setPrimaryVariants();
            //Calculate total price
            this.total_price = calculatePrice(this.product, this.cart_data)[
                "price"
                ];
          },
          () => {
            this.$router.replace("/404");
          }
      );
    },
  },
  created() {
    this.loadProduct();
  },
  watch: {
    cart_data: {
      handler() {
        //Calculate total price
        this.total_price = calculatePrice(this.product, this.cart_data)[
            "price"
            ];
      },
      deep: true,
    },
    "$route.params.id": function (newVal, oldVal) {
      if (this.$route.name == "ProductShow") {
        if (newVal != oldVal) {
          this.loadProduct();
        }
      }
    },
  },
};
</script>

<style lang="scss">
#productShow {
  width: 100%;
  float: left;
  margin-top: map-get($spacings, "seperator");
  margin-bottom: map-get($spacings, "seperator");

  .form-check {
    &.cover {
      margin-top: 3px;
      margin-bottom: 3px;
      margin-right: map-get($spacings, "xsmall");

      .form-check-input {
        border: 2px solid map-get($defaults, "border-color");

        &:checked {
          border: 2px solid map-get($colors, "twiks");
        }
      }

      .form-check-label {
        padding: map-get($spacings, "xsmall") map-get($spacings, "small");
        display: flex;
        align-items: center;

        img {
          border-radius: map-get($defaults, "border-radius");
          height: 20px;
          margin-right: map-get($spacings, "xsmall");
        }
      }
    }
  }

  .basic_details {
    & > .seperator {
      width: 100%;
      float: left;
      margin-top: map-get($spacings, "default");
      margin-bottom: map-get($spacings, "default");

      & > .body {
        width: 100%;
        float: left;
        margin-top: map-get($spacings, "xsmall");
      }
    }

    & > .title {
      margin-bottom: map-get($spacings, "small");
    }

    & > .controls {
      .add-to-cart,
      .add-to-package {
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .label {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #productShow {
    margin-top: map-get($spacings, "small");

    .basic_details {
      margin-top: map-get($spacings, "default");

      .seperator {
        &.controls {
          .add-to-cart,
          .add-to-package {
            margin-bottom: map-get($spacings, "default");
          }
        }
      }
    }
  }
}
</style>

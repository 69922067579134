const calculatePrice = function (product, cart_data, exclude_delivery = false) {
    //Original price
    let price = product.price;

    //Price by variant
    let variant_extra_price = 0;
    if (cart_data.variant != null) {
        //Find variant
        for (const variant_type of product.product_variants) {
            variant_type.variants.map((the_variant) => {
                if (the_variant.id === cart_data.variant) {
                    variant_extra_price = the_variant.extra_price;
                }
            });
        }
    }
    //Price + Variant Extra price
    price += variant_extra_price;

    //Multiplied by quanitty
    price *= cart_data.quantity;

    //Plus extras
    let product_extras_price = 0;
    if (cart_data.product_extras.length !== 0) {
        //Find variant
        for (const product_extra of product.product_extras) {
            //Check if extra exists in cart_data
            if (cart_data.product_extras[product_extra.id] !== undefined) {
                //Check if selected extras quantity != 0
                let quantity = cart_data.product_extras[product_extra.id];
                if (quantity > 0) {
                    let product_extra_price = product_extra.price * quantity;
                    product_extras_price += product_extra_price;
                }
            }
        }
    }
    price += product_extras_price;

    //Plus delivery price
    if (cart_data.delivery_type === "by_driver" && exclude_delivery === false) {
        price += product.delivery_price;
    }

    //Round the price
    price = parseFloat(price).toFixed(2);

    return {price};
};

const formatCartGroupForApi = function (cart_group, selected_address = null) {
    let formated_cart_group = JSON.parse(JSON.stringify(cart_group));
    //Append Address id
    if (selected_address !== null) {
        formated_cart_group['address_id'] = selected_address;
    }

    formated_cart_group.prescription_files = formated_cart_group.prescription_files.map((prescription_file) => {
        return prescription_file.name;
    });

    //Modify products object
    formated_cart_group.products.map((product) => {
        product['id'] = product.product_id;
        product.extras = [];

        //Delete keys
        delete product.product_id;
        delete product.delivery_type;

        //Prepare new product extras
        for (let productExtrasKey in product.product_extras) {
            product.extras.push({
                "id": productExtrasKey,
                "quantity": product.product_extras[productExtrasKey],
            });
        }

        //Delete old product extras
        delete product.product_extras;

        //Prepare variants
        product.variants = [];
        if (product.variant !== null) {
            product.variants = [parseInt(product.variant)];
        }

        //Delete old variant
        delete product.variant;
    });

    return formated_cart_group;
}

export {calculatePrice, formatCartGroupForApi};

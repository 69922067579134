<template>
  <div class="product-action-modal" v-show="modelValue">
    <div class="header">
      <div class="title font-weight-bold font-size-md mb-1">
        {{ header_title }}
      </div>
      <div class="subtitle">{{ header_subtitle }}</div>
      <button class="btn btn-clean close" @click="closeModal">
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
    <div class="body row">
      <div class="image col-auto" v-if="image != null">
        <img :src="image" />
      </div>
      <div class="body col">
        <slot name="main"></slot>
      </div>
    </div>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionModal",
  props: {
    modelValue: {
      type: Boolean,
    },
    timeout: {
      type: Number,
      default: 5000,
    },
    image: {
      type: String,
      default() {
        return null;
      },
    },
    header_title: String,
    header_subtitle: String,
  },
  data() {
    return {
      timeoutObject: null,
    };
  },
  methods: {
    closeModal() {
      this.stopTimeout();
      this.$emit("update:modelValue", false);
    },
    restartTimeout() {
      this.stopTimeout();
      this.timeoutObject = setTimeout(() => {
        //Close modal
        this.closeModal();
      }, this.timeout);
    },
    stopTimeout() {
      if (this.timeoutObject != null) {
        clearTimeout(this.timeoutObject);
      }
    },
  },
  watch: {
    modelValue: function () {
      if (this.modelValue === false) {
        //Clear Timeout
        this.stopTimeout();
      } else {
        //Restart Timeout
        this.restartTimeout();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-action-modal {
  position: fixed;
  z-index: 3;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 320px;
  padding: map-get($spacings, "large") map-get($spacings, "large");
  background-color: map-get($colors, "white");
  border-top-left-radius: map-get($defaults, "border-radius");
  box-shadow: -3px -3px 15px #00000029;

  .header {
    position: relative;
    border-bottom: 1px solid map-get($defaults, "border-color");
    padding-bottom: map-get($spacings, "small");

    .title {
      padding-right: 35px;
    }

    .btn.close {
      position: absolute;
      top: -7px;
      right: -10px;
      color: map-get($colors, "secondary");
    }
  }

  & > .body {
    padding: map-get($spacings, "medium") 0;
    clear: both;

    .image {
      img {
        width: 100%;
        max-width: 40px;
      }
    }
  }

  .footer {
    padding-top: map-get($spacings, "small");
    border-top: 1px solid map-get($defaults, "border-color");
  }
}
</style>

export default {
  methods: {
    nl2br: function(string) {
      if (string != null) {
        return string.replace(/(?:\r\n|\r|\n)/g, "<br>");
      } else {
        return "";
      }
    },
  },
};

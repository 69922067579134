<template>
  <div class="input-group input-counter" :class="size">
    <button class="btn btn-outline-secondary" :disabled="disabled" type="button" @click="decrease">
      -
    </button>

    <input
      type="text"
      :disabled="input_disabled"
      class="form-control"
      v-model="current_value"
    />
    <button class="btn btn-outline-secondary" :disabled="disabled" type="button" @click="increase">
      +
    </button>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: () => {
        return "default";
      },
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    input_disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    min: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    max: {
      type: [Number, Boolean],
      default: () => {
        return null;
      },
    },
    step: {
      type: Number,
      default: () => {
        return 1;
      },
    },
    modelValue: {
      type: [Number, String],
      default: () => {
        return 0;
      },
    },
  },
  name: "InputCounter",
  data() {
    return {
      current_value: 0,
    };
  },
  methods: {
    decrease() {
      if (this.current_value > this.min) {
        this.current_value -= this.step;
      }
    },
    increase() {
      if (this.current_value < this.max || this.max == null) {
        this.current_value += this.step;
      }
    },
    filterValue(value){
      if(this.min !== null && value < this.min){
        return this.min;
      }

      if(this.max !== null && value > this.max){
        return this.max;
      }

      return value;
    }
  },
  watch: {
    modelValue: function () {
      this.current_value = this.modelValue;
    },
    current_value: function () {
      this.$emit("update:modelValue", this.current_value);
    },
  },
  created() {
    this.current_value = this.filterValue(this.modelValue);
  }
};
</script>

<style lang="scss">
.input-counter {
  button {
    border: 1px solid map-get($defaults, "border-color");
    &:active,
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .form-control {
    text-align: center;
    border: 1px solid map-get($defaults, "border-color");
    padding-left: map-get($spacings, "xsmall");
    padding-right: map-get($spacings, "xsmall");

    &:active,
    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &:disabled {
      background-color: map-get($colors, "lightGray");
    }
  }

  &.small {
    button {
      padding-top: 0;
      padding-bottom: 0;
      height: 25px;
      line-height: 25px;
    }

    .form-control {
      height: 25px;
      line-height: 25px;
    }
  }
}
</style>

<template>
  <div
      class="seperator"
      v-if="product_ready === true && product_variants.length !== 0"
  >
    <div class="text-uppercase color-secondary">
      {{ $t("products.variants") }}
    </div>
    <div class="body">
      <!-- Each Variant Type -->
      <div
          class="variant_type Aligner items-left"
          v-for="(product_variant_type, index) in product_variants"
          :key="index"
      >
                <span class="label font-weight-bold">
                  {{ product_variant_type.name }} :
                </span>

        <!-- Each Variant -->
        <span v-if="product_variant_type.variants.length === 0">
                  {{ $t("products.variants_empty") }}
                </span>
        <div
            class="form-check cover"
            v-for="(product_variant, index) in product_variant_type.variants"
            :key="index"
        >
          <input
              class="form-check-input"
              type="radio"
              name="product_variant"
              :value="product_variant.id"
              :id="'product_variant_' + index"
              :checked="modelValue === product_variant.id"
              @change="$emit('update:modelValue', parseInt($event.target.value))"
          />
          <!-- Variant Label -->
          <label
              class="form-check-label"
              :for="'product_variant_' + index"
          >
            <!-- Variant Image -->
            <img
                v-if="product_variant.images.length != 0"
                :src="
                        window.Routes.getImage(
                          product_variant.images[0],
                          'preview'
                        )
                      "
                alt=""
            />
            <!-- Variant Name -->
            {{ product_variant.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductVariants",
  props: {
    modelValue: {
      type: Number
    },
    product_variants: {
      type: Object,
      default: () => {
        return {};
      }
    },
    product_ready: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
.variant_type {
  flex-wrap: wrap;

  .label {
    float: left;
    padding-right: map-get($spacings, "xsmall");
  }
}

</style>

<template>
  <div
    class="alert fade show"
    :class="[dismissible ? 'alert-dismissible' : '', 'alert-' + type]"
    role="alert"
  >
    <slot></slot>
    <button
      v-if="dismissible === true"
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "warning",
    },
    dismissible: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  name: "Alert",
};
</script>

<style lang="scss" scoped>
.alert {
  .btn-close {
    &:focus,
    &:active {
      outline: 0;
      box-shadow: none;
    }
  }
}
</style>
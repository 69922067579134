<template>
  <!-- Shipping method -->
  <div
      class="seperator delivery_type"
      v-if="product_ready === true && product.stock !== 0 && product.delivery_price !== -1"
  >
    <div class="text-uppercase color-secondary">
      {{ $t("products.shipping_method") }}
    </div>
    <div class="body">
      <div class="row">
        <!-- By Driver -->
        <div class="column col-12">
          <div class="row g-0 justify-content-between">
            <div class="col-auto">
              <div class="form-check form-check-inline">
                <input
                    class="form-check-input"
                    type="radio"
                    name="delivery_type"
                    value="by_driver"
                    :checked="modelValue === 'by_driver'"
                    @change="$emit('update:modelValue', $event.target.value)"
                    id="by_driver"
                />
                <label class="form-check-label" for="by_driver">
                  {{ $t("products.shipping_methods.by_driver") }}
                </label>
              </div>
            </div>
            <div class="col-auto Aligner items-left pt-1">
              <div>
                <!-- Preparing Time -->
                <span class="font-size-small me-3" v-if="product.delay !== 0 && product.delay !== undefined">
                 <span class="color-secondary">
                   {{ $t("products.preparing_time") }}:
                 </span>
                 <b class="color-black">
                   {{ product.delay }} {{ $t("global.timedata.min") }}
                 </b>
               </span>
                <!-- Delivery Fee -->
                <span
                    class="font-size-small color-secondary"
                    v-if="product_ready !== false"
                >
                  <span v-if="product.delivery_price === 0">{{ $t("products.free_shipping") }}</span>
                  <span v-if="product.delivery_price !== 0">
                    {{ $t("products.delivery") }}
                    <span class="font-weight-bold color-black">{{ formated_delivery_price }}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Self Pickup -->
        <div class="column col-12">
          <div class="row g-0 justify-content-between">
            <div class="col-auto">
              <div class="form-check form-check-inline">
                <input
                    class="form-check-input"
                    type="radio"
                    name="delivery_type"
                    value="self_pickup"
                    :checked="modelValue === 'self_pickup'"
                    @change="$emit('update:modelValue', $event.target.value)"
                    id="self_pickup"
                />
                <label class="form-check-label" for="self_pickup">
                  {{ $t("products.shipping_methods.self_pickup") }}
                </label>
              </div>
            </div>
            <div
                class="col-auto Aligner color-secondary items-left pt-1"
                v-if="product_ready === true">
              {{ $t("products.pickup_at", {name: product.shop.name}) }}
              <span class="ms-2 text-uppercase color-black font-weight-bold">{{ $t("global.free") }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import formatPrice from "@/includes/currency";

export default {
  name: "ProductDeliveryType",
  props: {
    modelValue: {
      type: String
    },
    product: {
      type: Object,
      default: () => {
        return {};
      }
    },
    product_ready: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formated_delivery_price: function () {
      return formatPrice(this.product.currency, this.product.delivery_price);
    },
  }
}
</script>

<style lang="scss" scoped>
.delivery_type {
  & > .body {
    & > .row {
      margin-left: 0;
      margin-right: 0;

      .column {
        border: 1px solid map-get($defaults, "border-color");
        border-bottom: 0;
        padding-top: map-get($spacings, "xsmall");
        padding-bottom: map-get($spacings, "xsmall");

        &:first-of-type {
          border-top-right-radius: map-get($defaults, "border-radius");
          border-top-left-radius: map-get($defaults, "border-radius");
        }

        &:last-of-type {
          border-bottom-right-radius: map-get($defaults, "border-radius");
          border-bottom-left-radius: map-get($defaults, "border-radius");
          border: 1px solid map-get($defaults, "border-color");
        }
      }
    }
  }
}
</style>

<template>
  <div class="product_details">
    <div class="title w-100 font-size-xl font-weight-bold">{{ $t("products.details") }}</div>
    <div class="details row">
      <div class="col-auto column">
        <div class="text-uppercase color-secondary title">{{ $t("products.price_range") }}</div>
        <div class="" v-if="product_ready === true">
              <span v-if="price_range.min_price != price_range.max_price">
                {{
                  formatPrice(product.currency, price_range.min_price)
                }} - {{ formatPrice(product.currency, price_range.max_price) }}
              </span>
          <span v-else>
                {{ formatPrice(product.currency, price_range.min_price) }}
              </span>
        </div>

        <div class="skeleton" v-if="product_ready === false">
          <div class="line skeleton_loader thick w-20 float-left"></div>
          <div class="float-left mx-1 color-gray">-</div>
          <div class="line skeleton_loader thick w-20 float-left"></div>
        </div>
      </div>
      <div class="col-auto column" v-if="product.weight !== 0">
        <div class="text-uppercase color-secondary title">{{ $t("products.weight") }}</div>
        <div class="" v-if="product_ready === true">{{ product.weight }}
          {{ $t("global.units.kg") }}
        </div>

        <div class="skeleton" v-if="product_ready === false">
          <div class="line skeleton_loader thick w-30 float-left"></div>
          <div class="float-left color-secondary mx-1">{{ $t("global.units.kg") }}</div>
        </div>
      </div>
      <div class="col-auto column">
        <div class="text-uppercase color-secondary title">{{ $t("products.categories") }}</div>
        <div class="" v-if="product_ready === true">
              <span v-for="(product_category, key) in product.product_categories" :key="key">
                <span v-if="key !== 0">, </span>
                {{ product_category.name }}
              </span>
        </div>

        <div class="" v-if="product_ready === false">
          <div class="float-left" v-for="(item, key) in [1,2,3,4]" :key="key">
            <div class="float-left color-gray pe-2" v-if="key !== 0">,</div>
            <div class="skeleton float-left">
              <div class="line skeleton_loader thick w-30px float-left"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body color-secondary">
      <div v-if="product_ready">
        <p v-html="nl2br(product.description)"></p>
      </div>

      <div v-if="product_ready === false">
        <div class="skeleton">
          <div class="line skeleton_loader thick w-70 float-left"></div>
          <div class="line skeleton_loader thick w-60 float-left"></div>
          <div class="line skeleton_loader thick w-80 float-left"></div>
          <div class="line skeleton_loader thick w-30 float-left"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatPrice from "@/includes/currency";
import StringHelpers from "@/mixins/StringHelpers";

export default {
  name: "ProductDetails",
  mixins: [StringHelpers],
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      }
    },
    product_ready: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatPrice(currency, price) {
      return formatPrice(currency, price);
    },
  },
  computed: {
    price_range: function () {
      let min_price = 0;
      let max_price = 0;
      let base_price = 0;
      //Check if product is ready
      if (this.product_ready === true) {
        base_price = this.product.price;
        min_price = this.product.price;
        max_price = this.product.price;
        //Check if product variants exist
        if (this.product.product_variants.length !== 0) {
          // Check if first variant type exists
          // At the moment only one variant type can be added
          if (this.product.product_variants[0] !== undefined) {
            for (const variant of this.product.product_variants[0].variants) {
              if (min_price > this.product.price + variant.extra_price) {
                min_price = this.product.price + variant.extra_price;
              }
              if (max_price < this.product.price + variant.extra_price) {
                max_price = this.product.price + variant.extra_price;
              }
            }
          }
        }
      }

      return {
        'base_price': base_price,
        'min_price': min_price,
        'max_price': max_price,
      };
    },
  }
}
</script>

<style lang="scss" scoped>
.product_details {
  margin-top: map-get($spacings, "seperator");
  padding-top: map-get($spacings, "seperator");
  padding-bottom: map-get($spacings, "seperator");
  border-top: 1px solid map-get($defaults, "border-color");

  .details {
    margin-top: map-get($spacings, "medium");
    margin-bottom: map-get($spacings, "medium");

    .column {
      border-right: 1px solid map-get($defaults, "border-color");

      .title {
        margin-bottom: 3px;
      }

      &:last-of-type {
        border-right: 0;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .product_details {
    margin-top:0;
    border-top:0;
    padding-top: map-get($spacings,"default");
  }
}
</style>
